<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form>
          <a-row>
            <a-col :sm="6" :xxl="4">
              <s-input
                title="商品标题"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.GoodsTitle"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-tree-select
                :labelCol="8"
                :valueCol="14"
                title="商品分类"
                :fieldNames="replaceFields"
                v-model:value="queryParams.GoodsCategoryId"
                :options="treeData"
              />
            </a-col>
            <a-col :sm="6" :xxl="4">
              <s-select
                title="查询用户"
                :labelCol="8"
                :valueCol="14"
                v-model:value="queryParams.UserId"
                :options="userList"
              />
            </a-col>
            <a-col :sm="6">
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
              <a-button
                type="primary"
                danger
                style="margin-left: 10px"
                @click="exportExcel()"
              >
                导出
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'quantity'">
              <a-tooltip>
                <template #title>修改库存</template>
                <a @click.stop="editQuantity(record)">
                  <a-tag color="blue" v-if="record.quantity >= 10">{{
                    record.quantity
                  }}</a-tag>
                  <a-tag color="red" v-else>{{ record.quantity }}</a-tag>
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
    <ChangeNum
      ref="changeNum"
      title="修改库存"
      label="库存"
      @ok="changeQuantity"
    />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  ExclamationCircleOutlined
} from '@ant-design/icons-vue'
import { SecondGoodsClass } from '@/apis/goods'
import page from '@/mixins/page'
import { dataToTree } from '@/utils'
import ChangeNum from '@/components/forms/ChangeNum'

import dayjs from 'dayjs'
import fileDownload from 'js-file-download'

const api = new SecondGoodsClass()
export default defineComponent({
  mixins: [page],
  components: {
    ChangeNum
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)
    const visiblePassword = ref(false)

    const id = ref('')
    const treeData = ref([])
    const units = ref([])
    const userList = ref([])

    const replaceFields = {
      value: 'id',
      key: 'id'
    }

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60
      },
      {
        title: '商品',
        key: 'goodsTitle',
        dataIndex: 'goodsTitle'
      },
      {
        title: '单位',
        key: 'unit',
        dataIndex: 'unit',
        width: 150
      },
      {
        title: '分类',
        key: 'goodsCategoryName',
        dataIndex: 'goodsCategoryName',
        width: 150
      },
      {
        title: '用户',
        key: 'name',
        dataIndex: 'name',
        width: 150
      },
      {
        title: '库存',
        key: 'quantity',
        dataIndex: 'quantity',
        width: 80
      }
    ]
    return {
      columns,
      loading,
      height,
      width,
      visiblePassword,
      treeData,
      replaceFields,
      id,
      units,
      userList
    }
  },
  created () {
    this.units = this.$store.state.app.units
    this.getDataList(1)
    this.getCategorys()
    this.getPlatformUsers()
  },
  methods: {
    async getPlatformUsers () {
      const list = this.$store.state.data.ALL_USER.length > 0 ? this.$store.state.data.ALL_USER : await this.$store.dispatch('GetAllUsers')

      const vlist = []
      list.map(e => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.userList = vlist
    },
    editQuantity (record) {
      this.id = record.id
      this.$refs.changeNum.open(record.quantity)
    },
    changeQuantity (e) {
      if (this.id) {
        api
          .quantity({
            id: this.id,
            quantity: e
          })
          .then(() => {
            this.$message.success('修改库存成功')
            this.getDataList(this.page.page)
            this.$refs.changeNum.handleClose()
          })
      }
    },
    async getCategorys () {
      const resp =
        this.$store.state.data.ALL_GOODSCATEGORYS.length > 0
          ? this.$store.state.data.ALL_GOODSCATEGORYS
          : await this.$store.dispatch('GetAllGoodsCategorys')
      const data = dataToTree(resp, 0)
      this.treeData = data
    },
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.title}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    changeStatus (goodsId, type, e) {
      console.log(goodsId, e, type)
      api[type](goodsId, e).then(() => {
        this.$message.success('操作成功')
        this.getDataList(this.page.page)
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    },

    exportExcel () {
      const q = { ...this.queryParams }
      q.Offset = (this.page.page - 1) * q.Limit

      api.exportExcel(q).then((resp) => {
        fileDownload(
          resp,
          '二级库报表' + dayjs().format('YYYYMMDDHHmmss') + '.xlsx'
        )
      })
    }
  }
})
</script>

<style lang="less" scoped>
.goods-info {
  display: flex;
  .goods-image {
    width: 100px;
    height: 100px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }
  .goods-title {
    padding-left: 10px;
    h1 {
      font-size: 14px;
      padding: 0;
      margin: 0;
      font-weight: 600;
      line-height: 30px;
    }
    p {
      font-size: 12px;
      color: #9b9b9b;
      line-height: 20px;
      height: 40px;
      padding: 0;
      margin: 0;
    }
    .goods-tags {
      font-size: 12px;
      line-height: 30px;
      height: 30px;
    }
  }
}

.search-box {
  position: relative;
  z-index: 9;
}
</style>
